<template>
  <section class="section-block">
    <SectionTitle title="顧客端預約連結" btn="更新連結" @edit="createLiff('appointment')" :hideBtn="!enableLiff" />
    <BaseElForm>
      <BaseElFormItem label="顧客端預約">
        <div class="copy-block">
          <a :href="liffUrl">
            {{ liffLink('appointment') ? liffUrl : '尚未設定，請聯繫客服' }}
          </a>
          <div>
            <BaseElButton
              :disabled="!liffUrl"
              class="copy-btn"
              type="primary"
              :class="{ disable: !liffUrl }"
              @click.prevent="copyLink(liffUrl)"
            >
              複製
            </BaseElButton>
          </div>
        </div>
      </BaseElFormItem>
    </BaseElForm>
  </section>
</template>

<script>
import SectionTitle from '@/components/Title/SectionTitle'
import { GetLiff, CreateLiff } from '@/api/shop'
import copy from 'clipboard-copy'
import { useShop } from '@/use/shop'
import { GetClientPageConfigLink, GetClientPageConfig } from '@/api/member/memberCenter'
import { defineComponent, onMounted, ref, computed } from 'vue'
import { get, find } from 'lodash'

export default defineComponent({
  name: 'MemberCenterLink',
  components: {
    SectionTitle,
  },
  setup() {
    const { shopId } = useShop()
    const enableLiff = ref(false)
    const link = ref({})
    const liff = ref('')
    const liffUrl = computed(() => {
      if (!liffLink('appointment')) return false
      if (enableLiff.value) return `${liffLink('appointment').liffUrl}`
      else return get(link.value, 'appointment')
    })
    const getClientPageConfig = async () => {
      const [res, err] = await GetClientPageConfig({ shopId: shopId.value })
      if (err) {
        window.$message.error(err)
        return
      }
      enableLiff.value = get(res, 'enableLiff', true)
    }
    const getClientPageConfigLink = async () => {
      const [res, err] = await GetClientPageConfigLink({ shopId: shopId.value })
      if (err) {
        window.$message.error(err)
        return
      }
      link.value = res
    }
    const copyLink = (link) => {
      copy(link)
      window.$message.success('已成功複製連結！')
    }
    const liffLink = (name) => {
      if (!liff.value) return false
      return find(liff.value, (item) => item.name === name)
    }
    const getLiff = async () => {
      try {
        const res = await GetLiff({ shopId: shopId.value })
        liff.value = res
      } catch (error) {
        liff.value = []
      }
    }
    const createLiff = async (name) => {
      try {
        await CreateLiff({ shopId: shopId.value, name })
        await getLiff()
        window.$message.success('更新 Liff 連結成功!')
      } catch (error) {
        console.log(error)
        window.$message.error(error)
      }
    }
    onMounted(async () => {
      await Promise.allSettled([
        getLiff(),
        getClientPageConfig(),
        getClientPageConfigLink(),
      ])
    })
    return {
      enableLiff,
      liff,
      copyLink,
      liffLink,
      createLiff,
      liffUrl,
    }
  },
})
</script>

<style lang="postcss" scoped>
.copy-block {
  @apply flex items-center;
}
.copy-btn {
  @apply h-[30px] rounded-full py-[3px] ml-[30px] px-[30px];
}
</style>
