<template>
  <section class="settings-section section-block">
    <SectionTitle
      cyBtn="edit-time-parameter"
      title="時段設定"
      btn="編輯"
      @edit="modal.edit = true"
    />
    <div>
      <BaseElForm label-position="left" label-width="250px">
        <BaseElFormItem label="時段間隔(分鐘) ：" class="settings-item">
          <span class="content">{{
            configData.timeUnit !== null ? configData.timeUnit : '尚未設定'
          }}</span>
        </BaseElFormItem>

        <BaseElFormItem label="時段偏移(分鐘) ：" class="settings-item">
          <span class="content">{{
            configData.timeUnitOffset !== null ? configData.timeUnitOffset : '尚未設定'
          }}</span>
        </BaseElFormItem>

        <BaseElFormItem label="最小預約時數(分鐘) ：" class="settings-item">
          <span class="content">{{
            configData.minReservationInterval !== null
              ? configData.minReservationInterval
              : '尚未設定'
          }}</span>
        </BaseElFormItem>

        <BaseElFormItem label="最大預約時數(分鐘) ：" class="settings-item">
          <span class="content">{{
            configData.maxReservationInterval ? configData.maxReservationInterval : '尚未設定'
          }}</span>
        </BaseElFormItem>

        <BaseElFormItem label="最晚可取消預約時間(分鐘)：" class="settings-item">
          <span class="content">{{
            configData.cancelInterval ? configData.cancelInterval : '尚未設定'
          }}</span>
        </BaseElFormItem>
      </BaseElForm>
    </div>

    <EditReservationBasicSettingsModal
      v-if="modal.edit"
      data-testid="edit-reservation-basic-settings-modal"
      :configData="configData"
      @close="modal.edit = false"
      @refresh="$emit('refresh')"
    />
  </section>
</template>

<script>
import { defineComponent, reactive } from 'vue'
import EditReservationBasicSettingsModal from './EditReservationBasicSettingsModal.vue'
export default defineComponent({
  name: 'ReservationBasicSettings',
  components: {
    EditReservationBasicSettingsModal,
  },
  props: {
    configData: {
      type: Object,
      default: () => ({}),
    },
  },
  setup (props, { emit }) {
    const modal = reactive({
      edit: false,
    })
    return { modal }
  },
})
</script>

<style lang="postcss" scoped></style>
